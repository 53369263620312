(function ($, window, undefined) {
    "use strict";

    var pluginName = "sidebarFilter",
        defaults = {},
        me,
        filterEvent;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;
    
        me.init(); 
    }
    
    $.urlParam = function(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
	return results !== null ? results[1] : 0;
    }
    
    $.extend(Plugin.prototype, {
        init: function () {
            
            if ($(window).width() <= 680) {
                $(".collapse-able-content").addClass('hide');
            }
            
            $(window).on('resize', function(){
                var win = $(this); //this = window
                if (win.width() <= 680) {
                    $(".collapse-able-content").addClass('hide');
                }
                else {
                    $(".collapse-able-content").removeClass('hide');
                }
            });
            
            var $form = me.$element.find('.sidebar form');
            
            $form.on('click', 'input[type="checkbox"]', me.onWidthFilter);
            //$form.on('click', 'span.exitF', me.unCheckFilter);
        },
        onWidthFilter: function (event) {
            if( filterEvent ) {
                filterEvent.abort();
            }
            var filters = [];
            var filtersNew = [];
            //console.log(filterEvent);
            $('input[type="checkbox"]:checked').each(function(key,value){
                let fname = $(value).attr('name').replace("[]", "");
                if(filters[fname] == undefined) {
                    filters[fname] = [];
                }
                if(filtersNew[fname] == undefined) {
                    filtersNew[fname] = {};
                    //filtersNew[fname][$(value).attr("id")] = [];
                }
                //console.log(fname,key,$(value).val());
                filters[fname].push($(value).val());
                filtersNew[fname][$(value).attr("id")] = {'value': $(value).val(), 'id': $(value).data('id'),'name': $(value).data('name')};
            });
            //*
            var width = [];
            $('input[name="width[]"]:checked').each(function(key,value){ width[key]= $(value).val(); });
            var length = [];
            $('input[name="length[]"]:checked').each(function(key,value){ length[key]= $(value).val(); });
            //*/
            var $link = $("#filterUrl");
            var link = $link.val().replace('http', 'https');
            // var link = $link.val();
            //console.log(filters,filtersNew);
            filterEvent = $.ajax({
                type: 'POST',
                //url: $link.val(),
                url: link,
                data: {width: width, length: length, filters: JSON.stringify(Object.assign ( {}, filters)), filtersNew: JSON.stringify(Object.assign ( {}, filtersNew)), display: $.urlParam('display'), order: $.urlParam('order')},
                success: function (response) {
                    var $typeView = $(response.typeView).parent()
                    var $filterButton = $('.filterBG');
                    $filterButton.html(response.filterView);
                    $typeView.html(response.view);
                    $('#articleCount').html(response.articleCount);
                    $('#countArticle').html(response.articleCount);
                    if ($(window).width() <= 680) {
                        $(".collapse-able-content").addClass('hide');
                    }
                    
                    var $exitF = $("span.exitF");
                    $exitF.on('click', me.unCheckFilter);
                    Foundation.reflow($typeView.find('img'));
                    //$(document).stockInfo();
                },
                beforeSend: function( xhr ) {
                    var $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>');
                    var $typeView = $('.article-listing');
                    var $filterButton = $('.filterBG');
                    $typeView.empty();
                    $filterButton.empty();
                    $typeView.append($loader);
                },
                error: function (xhr,status,err) {
                    //alert("DEBUG: status "+status+" \nError: "+err);
                    if(status !== "abort") {
                        alert('error');
                    }
                },
                complete: function (e) {
                    $(document).stockInfo();
                }
            });
        },
        unCheckFilter: function (event) {
            var $this = $(this);
            var unchecked = $this.data('checked').split(', ');
            unchecked.forEach( function(item, index) { 
                $("#"+item).click();
            });
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
