(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "account",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $window = $(window),
                $shippingAddresses = me.$element.find('.shipping-addresses'),
                $openNotesButton = me.$element.find('.button.open-notes'),
                $openUnreadNotesButton = me.$element.find('.button.open-unread-notes'),
                $openNotesFormButton = me.$element.find('.button.open-send-new-notes'),
                $setPrimaryLinks = $shippingAddresses.find('a.set-primary'),
                $downloadButton = me.$element.find('.button.download'),
                $topicFilter = me.$element.find('select.topic_filter')

            $window.on('resize', me.onResize);
            $window.trigger('resize');

            $setPrimaryLinks.on('click', me.onSetPrimaryAddressClick);
            $openNotesButton.on('click', me.onOpenNotes);
            $openUnreadNotesButton.on('click', me.onOpenUnreadNotes);
            $downloadButton.on('click', me.onOpenAvailableDocuments);
            $openNotesFormButton.on('click', me.onOpenNotesForm);
            $topicFilter.on('change', me.filterTopic);
            me.makeDownloadClick();
        },
        filterTopic: function (event){
            if ($(this).val().length) {
                window.location.href = $(this).val();
            }
        },
        onOpenNotesForm: function (event) {
            event.preventDefault();
            var $reveal = $('.reveal.ordernotesform');
            me.getNotesForm($reveal, $(this).attr('href'));
        },
        onOpenAvailableDocuments: function (event){
            event.preventDefault();
            var $reveal = $('.reveal.availableDocuments');
            me.getAvailableDocuments($reveal, $(this).attr('href'));
        },
        onOpenNotes: function (event){
            event.preventDefault();

            var $reveal = $('.reveal.ordernotes'),
                $boton = $(".open-unread-notes"),
                type = 'all';
            $reveal.data('type', type);
            me.getNotes($reveal, type, $boton, $(this).attr('href'));
        },
        onOpenUnreadNotes: function (event){
            event.preventDefault();

            var $reveal = $('.reveal.ordernotes'),
                $boton = this,
                type = 'unread';
            $reveal.data('type', type);
            me.getNotes($reveal, type, $boton,  $(this).attr('href'));
        },
        getNotes: function ($reveal,type,$boton,href){
            $.ajax({
                type: 'get',
                data: {type: type},
                url: href,
                success: function (response) {
                    $boton.remove();
                    $(".list-notes").remove();
                    $reveal.append(response);
                    $reveal.foundation('open');

                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }

            });
        },
        getMailTopicInfo:function (event){
            console.log(event);
            $.ajax({
                type: 'post',
                url: $("#mail_info_url").attr('attr-data'),
                data: {'mailTopicId':event.target.value},
                success: function (response) {
                    console.log(response)
                    if(response.attachment){
                        $(".order_note_attachment").show()
                    }else{
                        $(".order_note_attachment").hide()
                    }
                    $(".mail_topic_infolabel").html(response.info)
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }

            });
        },
        getNotesForm: function ($reveal,href){
            $.ajax({
                type: 'get',
                url: href,
                success: function (response) {
                    $(".ajax-container.add-note").remove();
                    $reveal.find('.content-stock').append(response);
                    $('select.mail_topic').change(me.getMailTopicInfo)
                    $('select.mail_topic').change()

                    $reveal.addClass('large');
                    $reveal.foundation('open');
                },

                beforeSend: function( xhr ) {
                    $reveal.addClass('stock-info');
                    $reveal.find('.content-stock').html("");
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }

            });
        },
    makeDownloadClick: function(){
    $('.downloadFile.Elias').click(me.downLoadLink);
    },
    downLoadLink: function(){

        var url = $(this).attr("data-href")
        console.log(url)
        window.open(url)
        /*$.ajax({
            type: 'get',
            url: url,
            success: function (response) {
                consoole.log(response);
            },

            error: function () {
                alert('error');
            },

            complete: function (e) {
            }

        });*/
    },
            getAvailableDocuments: function ($reveal,href){
            $.ajax({
                type: 'get',
                url: href,
                success: function (response) {
                    console.log(response)
                
                    $(".availabaleDocuments.body").empty();
                    $(".availabaleDocuments.body").append(response.html.documents);
                    me.makeDownloadClick();
                    $reveal.foundation('open');
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }

            });
        },
        onSetPrimaryAddressClick: function (event) {
            event.preventDefault();

            var $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $overlay = $('body>.loader-overlay'),
                $shippingAddresses = me.$element.find('.shipping-addresses'),
                $billingAddress = me.$element.find('.billing-address');

            $('body').append($loader);
            $overlay.addClass('is-visible');

            $.ajax({
                type: 'post',
                url: $(event.target).attr('href'),
                success: function (response) {
                    $shippingAddresses.html(response.html.shipping);
                    $billingAddress.html(response.html.billing);

                    var $setPrimaryLinks = $shippingAddresses.find('a.set-primary');
                    $setPrimaryLinks.on('click', me.onSetPrimaryAddressClick);

                    $loader.remove();
                    $overlay.removeClass('is-visible');
                },

                error: function () {
                    alert('error');

                    $loader.remove();
                    $overlay.removeClass('is-visible');
                },

                complete: function (e) {
                }

            });
        }

    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

    $(".moreinfo").click(function (){
        var $parent = $(this).closest(".subcontent_details");
        $parent.toggleClass("blueline");
        var $details = $parent.siblings(".subcontent_more_details");
        if($details.first()){
            $details.first().toggle();
        }
    });
    $('.closecomm').click(function (){
        $(this).closest(".comments").hide();
    })
    $(".toggleoptions").click(function (){
        var $parent = $(this).closest(".card").first();
        console.log($parent)
        var $children = $parent.find(".optionHide");
        $(this).children(".initial").toggle();
        $children.toggle();

    });

})(jQuery, window, document);
