(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "checkout",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $changeBillingAddressButton = me.$element.find('.button.change-billing-address'),
                $changeShippingAddressButton = me.$element.find('.button.change-shipping-address'),
                $changeBranch = me.$element.find('a#change-branch'),
                $changedBranch = me.$element.find('.branch-select'),
                $reveal = $('.reveal');

            $reveal.on('closed.zf.reveal', me.onRevealClose);
            $changeBillingAddressButton.on('click', me.onChangeBillingAddressClick);
            $changeShippingAddressButton.on('click', me.onChangeShippingAddressClick);
            $changeBranch.on('click', me.onChangeBranchClick);
            $changedBranch.on('click', me.onChangedBranchClick);
        },
        onRevealClose: function (event) {
            $(event.target).find('.content-stock h2').remove();
            $(event.target).find('.ajax-container').remove();
        },
        onChangeShippingAddressClick: function (event) {
            event.preventDefault();

            var $reveal = $('.reveal'),
                type = 'shipping';

            $reveal.data('type', type);
            me.sendAjaxRequest($reveal, type, $(this).attr('href'));
        },
        onChangeBillingAddressClick: function (event) {
            event.preventDefault();

            var $reveal = $('.reveal'),
                type = 'billing';

            $reveal.data('type', type);
            me.sendAjaxRequest($reveal, type, $(this).attr('href'));
        },
        onChangeBranchClick: function (event) {
            event.preventDefault();

            var $reveal = $('.reveal'),
                type = 'branch',
                $link = $(this);

            $reveal.data('type', type);
            $.ajax({
                type: 'post',
                url: $link.attr('href'),
                success: function (response) {
                    $reveal.find('.content-stock').append(response);

                    $reveal.addClass('large');

                    $reveal.foundation('open');
                    var $changedBranch = me.$element.find('.branch-select');
                    $changedBranch.on('click', me.onChangedBranchClick);
                },

                beforeSend: function( xhr ) {
                    $reveal.addClass('stock-info');
                    $reveal.find('.content-stock').html("");
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onChangedBranchClick: function (event) {
            event.preventDefault();

            var $reveal = $('.reveal'),
                type = $(this).data('branch'),
                $link = $("#branch-form");


            $.ajax({
                type: 'post',
                data: {branch: type},
                url: $link.attr('action'),
                success: function (response) {
                    $reveal.find('.close-button').click();
                    $("#branch-selected").html(response.branch);
                    $("#branch-id").val(response.branch-id);
                    //console.log(response);
                },

                beforeSend: function( xhr ) {
                    $reveal.addClass('stock-info');
                    $reveal.find('.content-stock').html("");
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        sendAjaxRequest: function ($reveal, type, href, remove = false) {
            $.ajax({
                type: 'get',
                data: {type: type},
                url: href,
                success: function (response) {
                    if (remove) {
                        $reveal.find('.ajax-container').remove();
                    }

                    $reveal.find('.content-stock').append(response)
                    $reveal.addClass('large');

                    $reveal.foundation('open');
                    var $listItems = $reveal.find('.list-item');

                    $reveal.find('form').on('submit', me.onChangeAddressFormSubmit);
                    $reveal.find('.add-button').on('click', me.onAddButtonClick);
                    $listItems.on('click', '*', me.onListItemClick);
                    $listItems.find('a.change-address').on('click', me.onListItemLinkClick);
                },

                beforeSend: function( xhr ) {
                    $reveal.addClass('stock-info');
                    $reveal.find('.content-stock').html("");
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }

            });
        },
        onAddButtonClick: function (event) {
            event.preventDefault();

            $.ajax({
                type: 'get',
                url: $(this).attr('href'),
                success: function (response) {
                    var $reveal = $('.reveal'),
                        $container = $reveal.find('.ajax-container');

                    $container.remove();
                    $reveal.find('.content-stock').html(response);

                    var $backButton = $reveal.find('.back-button'),
                        $saveButton = $reveal.find('.save-button');

                    $backButton.on('click', me.onBackButtonClick);
                    $saveButton.on('click', me.onSaveButtonClick);
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onListItemLinkClick: function (event) {
            event.preventDefault();

            $.ajax({
                type: 'get',
                url: $(this).attr('href'),
                success: function (response) {
                    var $reveal = $('.reveal'),
                        $container = $reveal.find('.ajax-container');

                    $container.remove();
                    $reveal.find('.content-stock').html(response);

                    var $backButton = $reveal.find('.back-button'),
                        $saveButton = $reveal.find('.save-button');

                    $backButton.on('click', me.onBackButtonClick);
                    $saveButton.on('click', me.onSaveButtonClick);
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onSaveButtonClick: function (event) {
            event.preventDefault();
            var $form = $(this).closest('form');

            $.ajax({
                type: 'post',
                data: $form.serialize(),
                url: $form.attr('action'),
                success: function (response) {
                    var $reveal = $('.reveal .content-stock'),
                        $container = $reveal.find('.ajax-container');

                    $container.remove();
                    $reveal.append(response);

                    if ($(response).hasClass('shipping-addresses')) {
                        var $listItems = $reveal.find('.list-item');

                        $reveal.find('form').on('submit', me.onChangeAddressFormSubmit);
                        $reveal.find('.add-button').on('click', me.onAddButtonClick);
                        $listItems.on('click', '*', me.onListItemClick);
                        $listItems.find('a.change-address').on('click', me.onListItemLinkClick);
                    } else {
                        var $backButton = $reveal.find('.back-button'),
                            $saveButton = $reveal.find('.save-button');

                        $backButton.on('click', me.onBackButtonClick);
                        $saveButton.on('click', me.onSaveButtonClick);
                    }
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onBackButtonClick: function (event) {
            var $reveal = $('.reveal'),
                type = $reveal.data('type');

            me.sendAjaxRequest($reveal, type, $(this).data('url'), true);
        },
        onListItemClick: function (event) {
            var $target = $(event.target),
                $parent = $(this).parent(),
                $radio = $parent.find('input');

            if ($target.is('a')) return;

            $radio.prop("checked", true);
        },
        onChangeAddressFormSubmit: function (event) {
            event.preventDefault();

            var $reveal = $(event.target).closest('.reveal'),
                type = $reveal.data('type'),
                data = $(event.target).serialize() + "&type=" + type;

            $.ajax({
                type: 'post',
                data: data,
                url: $(event.target).attr('action'),
                success: function (response) {
                    $('.reveal').foundation('close');
                    window.location.reload();
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

    $("#project").change(function (){
        if($(this).is(":checked")){
            $(".news-body").removeClass(" hide-for-small-only");
        }else{
            $(".news-body").addClass(" hide-for-small-only");
        }
    })

})(jQuery, window, document);
