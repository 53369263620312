(function ($, window, undefined) {
    "use strict";

    var pluginName = "stockInfo",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;
    
        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $stockButton = me.$element.find('.in-stock'),
                $reveal = me.$element.find('.reveal');

            $reveal.on('closed.zf.reveal', me.onRevealClose);
            $stockButton.on('click', me.onShowStock);
            /*
            $( document ).ajaxComplete(function() {
                $stockButton = me.$element.find('.in-stock');
                $stockButton.on('click', me.onShowStock);
            });
            //*/
        },
        
        reloadStock: function() {
            console.log("qwerty1");
            var $stockButton = me.$element.find('.in-stock');
            $stockButton = me.$element.find('.in-stock');
            $stockButton.on('click', me.onShowStock);
        },
        
        onRevealClose: function (event) {
            var $reveal = $(event.target);

            $reveal.removeClass('large');
            $reveal.removeClass('stock-info');
            $(event.target).find('.content-stock h2').remove();
            $reveal.find('.ajax-container').remove();
        },
        
        onShowStock: function (event) {
            event.preventDefault();
            var $link = $(this),
                $reveal = me.$element.find('.reveal');

            $.ajax({
                type: 'post',
                url: $link.attr('href'),
                success: function (response) {
                    $reveal.find('.content-stock').append(response);

                    $reveal.addClass('large');

                    $reveal.foundation('open');
                },
                
                beforeSend: function( xhr ) {
                    $reveal.addClass('stock-info');
                    $reveal.find('.content-stock').html("");
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
            
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
