(function ($, window, undefined) {
    "use strict";

    var pluginName = "smoothScroll",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;
        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $('a[href*="#"]').on('click', me.onAnchorLinkClick);
        },
        onAnchorLinkClick: function (event) {
            var target = this.hash,
                $target = $(target);

            if (target.length) {
                event.preventDefault();
                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top
                }, 900, 'swing', function () {
                    window.location.hash = target;
                });
            }
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
