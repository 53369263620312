(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "cookieNotice",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $cookieNotice = $('.cookie-notice');
            $cookieNotice.find('.button').on('click', me.onCookieNoticeButtonClick)
        },
        onCookieNoticeButtonClick: function (event) {
            $.cookie('cookie-consent-accepted', true, {expires: 365});
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
