(function ($, window, undefined) {
    "use strict";

    var pluginName = "languageSwitcher",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            me.initLanguageSwitcher();
        },
        initLanguageSwitcher: function () {
            var $form = me.$element.find('form.language-switcher'),
                $select = $form.find('select');

            $select.on('change', me.onSelectChange);
        },
        onSelectChange: function (event) {
            var $form = $(this).parent();

            $form.submit();
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
