(function ($, window, undefined) {
    "use strict";

    var pluginName = "buyboxMobile",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;
        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $(window).on('load', me.windowOnLoad);
            $(window).on('resize', me.windowOnResize);
        },
        windowOnLoad: function (event) {
            $(window).trigger('resize');
        },
        windowOnResize: function (event) {
            var $mobileBuyboy = me.$element.find('.buybox-mobile'),
                mobileBuyboyHeight = $mobileBuyboy.outerHeight(),
                $quickMenu = $mobileBuyboy.find('.quick-menu'),
                quickMenuHeight = $quickMenu.outerHeight();

            $mobileBuyboy.css("transform", `translateY(${mobileBuyboyHeight - quickMenuHeight}px)`);
            $('body').css('padding-bottom', quickMenuHeight);
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
