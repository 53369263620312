(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "megaMenu",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName,
            me.timer = 0;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $mainNavigation = me.$element.find('.main-navigation'),
                $navigationLinks = $mainNavigation.find('.navigation-list .navigation-entry .navigation-link'),
                isTouch = $('html').data('whatinput') === 'touch',
                isMegaMenuActive = $mainNavigation.data('mega-menu') !== undefined;

            if (isMegaMenuActive) {
                if (isTouch) {
                    $navigationLinks.on('click', me.onNavigationLinkMouseEnter);
                    $('body').on('click', $.proxy(me.onBodyMouseMove, me));
                } else {
                    $navigationLinks.on('mouseenter', me.delay(me.onNavigationLinkMouseEnter, 100));
                    $navigationLinks.on('mouseleave', me.clearTimer);

                    $('body').on('mousemove', $.proxy(me.onBodyMouseMove, me));
                }
            }
        },
        clearTimer: function () {
            clearTimeout(me.timer);
        },
        onBodyMouseMove: function (event) {
            var $mainNavigation = me.$element.find('.main-navigation'),
                target = event.target,
                $megaMenuContainer = $mainNavigation.find('.mega-menu-container');

            if ($megaMenuContainer.hasClass('is-open')) {
                if (me.hasMouseLeftValidElements(target)) {
                    me.closeMenu();
                }
            }
        },
        hasMouseLeftValidElements: function (target) {
            var $mainNavigation = me.$element.find('.main-navigation'),
                $megaMenuContainer = $mainNavigation.find('.mega-menu-container');

            if ($megaMenuContainer.hasClass('is-open')) {
                var $activeMegaMenu = $megaMenuContainer.find('.mega-menu.is-active'),
                    webGroup = $activeMegaMenu.data('web-group'),
                    $navigationList = $mainNavigation.find('.navigation-list'),
                    $activeNavigationLink = $navigationList.find('.navigation-entry .navigation-link[data-web-group="' + webGroup + '"]'),
                    $activeNavigationEntry = $activeNavigationLink.closest('.navigation-entry'),
                    $activeNavigationText = $activeNavigationLink.find('.navigation-text');

                return !(me.$element.get(0) === target ||
                    $megaMenuContainer.get(0) === target ||
                    $.contains($megaMenuContainer.get(0), target) ||
                    $activeNavigationLink.get(0) === target ||
                    $activeNavigationText.get(0) === target ||
                    $activeNavigationEntry.get(0) === target);
            }
        },
        onNavigationLinkMouseEnter: function (event) {
            event.preventDefault();

            var $mainNavigation = me.$element.find('.main-navigation'),
                $megaMenuContainer = $mainNavigation.find('.mega-menu-container'),
                $megaMenus = $megaMenuContainer.find('.mega-menu'),
                $hoveredNavigationLink = $(this),
                $hoveredNavigationEntry = $hoveredNavigationLink.closest('.navigation-entry'),
                megaMenuUrl = $hoveredNavigationLink.data('megamenu-url'),
                webGroup = $hoveredNavigationLink.data('web-group');

            if (megaMenuUrl && webGroup) {
                var $existingMegaMenu;
                $.each($megaMenus, function () {
                    if ($(this).data('web-group') === webGroup) {
                        $existingMegaMenu = $(this);
                    }
                });

                if ($existingMegaMenu === undefined || !$existingMegaMenu.length) {
                    if (megaMenuUrl) {
                        $.ajax({
                            url: megaMenuUrl,
                            success: function (response) {
                                var $response = $(response);
                                $megaMenuContainer.append($response);

                                me.closeMenu();

                                var $subGroupLinks = $response.find('a.group');

                                $subGroupLinks.on('mouseover', me.onSubMenuLinkHover);

                                $response.addClass('is-active');
                                Foundation.reflow($response.find('img'));
                                Foundation.reflow($response.find('[data-equalizer]'));

                                $hoveredNavigationLink.addClass('hovered');
                                $hoveredNavigationEntry.addClass('hovered');

                                $megaMenuContainer.addClass('is-open');
                                $('body').addClass('mega-menu-opened');
                            }
                        });
                    } else {
                        me.closeMenu();
                    }
                } else {
                    me.closeMenu();

                    $megaMenuContainer.addClass('is-open');

                    if ($existingMegaMenu) {
                        $existingMegaMenu.addClass('is-active');

                        $hoveredNavigationLink.addClass('hovered');
                        $hoveredNavigationEntry.addClass('hovered');

                        $('body').addClass('mega-menu-opened');
                    }
                }
            }
        },
        onSubMenuLinkHover: function (event) {
            var $subMenuLink = $(this),
                $subMenuListItem = $subMenuLink.closest('li'),
                $megaMenu = $subMenuLink.closest('.mega-menu'),
                $allSubMenuListItems = $megaMenu.find('.menu-element li'),
                $descriptions = $megaMenu.find('.description'),
                $images = $megaMenu.find('.menu-image'),
                linkGroup = $subMenuLink.data('web-group');

            me.activateMenuElement($descriptions, linkGroup);
            me.activateMenuElement($images, linkGroup);
            $allSubMenuListItems.removeClass('hovered');
            $subMenuListItem.addClass('hovered');
        },
        activateMenuElement: function ($elements, groupId) {
            $.each($elements, function () {
                var $element = $(this);

                if ($element.data('web-group') === groupId) {
                    $element.removeClass('hide');
                } else {
                    $element.addClass('hide');
                }
            });

        },
        closeMenu: function () {
            var $megaMenuContainer = me.$element.find('.main-navigation .mega-menu-container'),
                $navigationLinks = me.$element.find('.main-navigation .navigation-list .navigation-link'),
                $navigationEntries = me.$element.find('.main-navigation .navigation-list .navigation-entry');

            $megaMenuContainer.removeClass('is-open');
            $megaMenuContainer.find('.mega-menu').removeClass('is-active');
            $navigationEntries.removeClass('hovered');
            $navigationLinks.removeClass('hovered');

            $('body').removeClass('mega-menu-opened');
        },
        delay: function (callback, ms) {
            return function () {
                var context = this, args = arguments;
                clearTimeout(me.timer);
                me.timer = setTimeout(function () {
                    callback.apply(context, args);
                }, ms || 0);
            };
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
