(function ($, window, undefined) {
    "use strict";

    var pluginName = "amountField",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;
        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            me.$element.on('click', '.input-group.amount button', me.onButtonClick);
            me.$element.on('click', '.input-group.amount input', me.onInputClick);
            me.$element.on('change', '.input-group.amount input', me.onInputChange);
            me.$element.on('keyup', '.input-group.amount input', me.delay(me.onInputKeyUp, 500));
        },
        onInputKeyUp: function (event) {
            $(this).trigger('change');
        },
        onButtonClick: function (event) {
            event.preventDefault();

            var $button = $(this),
                $input = $button.parent().find('input'),
                value = parseFloat($input.val());

            if (isNaN(value)) {
                $input.val(0);
            }

            if ($button.hasClass('minus')) {
                if (value - 1 > 0) {
                    $input.val(value - 1);
                } else {
                    $input.val(0);
                }
            }

            if ($button.hasClass('plus')) {
                $input.val(value + 1);
            }

            $input.trigger('change');
        },
        onInputClick: function (event) {
            event.preventDefault();

            $(this).select();
        },
        onInputChange: function (event) {
            var $button = $(this),
                syncWith = $button.data('sync-with'),
                $parent = $button.parent(),
                $input = $parent.find('input'),
                value = $input.val(),
                minValue = 0,
                maxValue = 9999;

            event.preventDefault();

            if (syncWith) {
                var $target = $('#' + syncWith);

                if ($target.length) {
                    $target.val($button.val());
                }
            }

            if ($input.data('min-value')) {
                minValue = $input.data('min-value');
            }

            if (value <= minValue) {
                $parent.find('button.minus').prop('disabled', true);
                $input.val(minValue);
            } else if (value > maxValue) {
                $parent.find('button.minus').prop('disabled', false);
                $input.val(maxValue);
            } else {
                $parent.find('button.minus').prop('disabled', false);
            }
            var $form = $parent.closest('.add-to-cart-form'),
                url = $form.data('process-form-url');
            if(!$form.find("input[name='cutting-length[]']").hasClass("error")) {
                me.processValue(url, $form.serializeArray());
            }

        },
        processValue:function (url, data){
            if (url !== undefined) {
                $.ajax({
                    type: 'post',
                    url: url,
                    data: data,
                    success: function (response) {
                        console.log(response);
                        $("#search-price-"+response['article']).html(response['value']);
                        $(window).resize();
                    },

                    error: function () {
                        alert('error');
                    },

                    complete: function (e) {
                    }
                });
            }
        },
        delay: function (callback, ms) {
            var timer = 0;

            return function () {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    callback.apply(context, args);
                }, ms || 0);
            };
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
