(function ($, window, undefined) {
    "use strict";

    var pluginName = "productDetail",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName,
            me.buyBoxDefaults = {
                'price-total': 0,
                'price-vat': 0,
                'amount-meter': 0,
                'amount-kilogram': 0,
                'material-price-total': 0,
                'material-price-vat': 0,
                'division-price-total': 0,
                'division-price-vat': 0,
            };
        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $image = me.$element.find('.images>a>img'),
                $form = me.$element.find('.add-to-cart-form'),
                //$reveal = me.$element.find('.reveal'),
                $cuttingAmountWrapper = $form.find('.cutting-amount-wrapper'),
                $addRowButton = $cuttingAmountWrapper.find('button.add-row'),
                $thumbnails = me.$element.find('.thumbnail-container .image');

            //$reveal.on('closed.zf.reveal', me.onRevealClose);

            // Image zoom
            $image
                .wrap('<span style="display:inline-block"></span>')
                .css('display', 'block')
                .parent()
                .zoom();

            $form.on('change', 'input[name="amount"], input[name="cutting-amount[]"]', me.onAmountInputChange);
            $form.on('click', 'input[name="certificate"]', me.onCertificateChange);
            $form.on('click', 'a.quantity-discounts', me.onQuantityDiscountsLinkClick);
            $form.on('change', 'input[name="cutting-length[]"], input[name="cutting-width[]"]', me.delay(me.onDimensionInputChange, 500));

            $('#product_image_element').on('click', me.onShowProductImage);

            $addRowButton.on('click', me.onAddRowClick);
        },
        onCertificateChange: function (event) {
            $('input[name="amount"]').trigger('change');
        },
        onShowProductImage: function(event){
            event.preventDefault();

            let $reveal = $('.reveal');


            $reveal.addClass('stock-info');
            $reveal.find('.content-stock').html("");

            $reveal.find('.content-stock').append(`
                <div style="text-align: center;">
                    <img src="${ $(this)[0].href }" />
                </div>
            `);
            $reveal.addClass('large');
            $reveal.foundation('open');

        },
        onAddRowClick: function (event) {
            event.preventDefault();

            var $addRowButton = $(this),
                $cuttingAmountWrapper = $addRowButton.closest('.cutting-amount-wrapper'),
                $cuttingAmount = $cuttingAmountWrapper.find('.cutting-amount'),
                maxRows = $cuttingAmount.data('max-rows'),
                template = $cuttingAmount.data('template');

            if (template.length) {
                template = $.parseJSON(template);
                maxRows++;

                var $template = $(template.replace(/{key}/g, maxRows));

                if ($template) {
                    $cuttingAmount.append($template);

                    $cuttingAmount.data('max-rows', maxRows);
                }
            }
        },
        onQuantityDiscountsLinkClick: function (event) {
            var $link = $(this),
                $reveal = me.$element.find('.reveal');

            event.preventDefault();

            $.ajax({
                type: 'get',
                url: $link.attr('href'),
                success: function (response) {
                    $reveal.append(response);

                    $reveal.addClass('large');

                    $reveal.foundation('open');
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onDimensionInputChange: function (event) {
            event.preventDefault();

            var $input = $(this),
                value = $input.val(),
                max = parseInt($input.attr('max')),
                min = parseInt($input.attr('min')),
                $amountConfiguration = $input.closest('.amount-configuration'),
                $amountInput = $amountConfiguration.find('input.amount'),
                $errormsg = $("#error-"+$input.attr("id"));

            if(!Number.isInteger(value)){
                value = parseInt(value);
                $input.val(value);
            }
            if (isNaN(value) || value === "") {
                $input.val("");
            } else {
                if (value < min) {
                    $errormsg.text("Min value: "+ min);
                    $input.addClass("error");
                    $errormsg.show();
                } else if (value > max) {
                    $errormsg.text("Max value: "+ max);
                    $input.addClass("error");
                    $errormsg.show();
                }else{
                    $input.removeClass("error");
                    $errormsg.hide(); 
                }
            }

            $amountInput.trigger('change');
        },
        onAmountInputChange: function (event) {
            event.preventDefault();
            var $form = me.$element.find('.add-to-cart-form'),
                url = $form.data('process-form-url');

            if(!$form.find("input[name='cutting-length[]']").hasClass("error")) {
                
                me.processForm(url, $form.serializeArray());
            }
            //console.log(this, $form.find("input.amount").val());
        },
        processForm: function (url, data) {
            if (url !== undefined) {
                $.ajax({
                    type: 'post',
                    url: url,
                    data: data,
                    success: function (response) {
                        var $buybox = me.$element.find('.buybox'),
                            $mobileBuyboxOverview = me.$element.find('.buybox-mobile .overview'),
                            $productInformation = me.$element.find('.product-information');

                        $buybox.replaceWith(response['buybox-html']);
                        $productInformation.replaceWith(response['product-information-html']);
                        $mobileBuyboxOverview.replaceWith(response['buybox-mobile-overview-html']);

                        $(window).resize();
                    },

                    error: function () {
                        alert('error');
                    },

                    complete: function (e) {
                    }
                });
            }
        },
        fillBuyBox: function (data) {
            var $buybox = me.$element.find('.buybox'),
                $buyboxHeader = $buybox.find('.buybox-header'),
                $buyboxBody = $buybox.find('.buybox-body'),
                $priceTotal = $buyboxHeader.find('.price-total'),
                $priceVat = $buyboxHeader.find('.price-vat'),
                $amountMeter = $buyboxBody.find('.meter'),
                $amountKilogram = $buyboxBody.find('.kilogram'),
                $materialPriceTotal = $buyboxBody.find('.material-price-total'),
                $materialPriceVat = $buyboxBody.find('.material-price-vat'),
                $divisionPriceTotal = $buyboxBody.find('.division-price-total'),
                $divisionPriceVat = $buyboxBody.find('.division-price-vat'),
                $submitCartButton = $buybox.find('button[type="submit"].cart-button'),
                $addToShoppingListButton = $buybox.find('button[type="submit"].add-to-shopping-list'),
                $displayUnitsField = $buybox.find('.display-units'),
                isInquiryArticle = $buybox.data('is-inquiry-article');

            if ($displayUnitsField.length) {
                $displayUnitsField.html('');

                var i = 0;
                $.each(data['display-unit-amounts'], function () {
                    if (i === 0) {
                        $displayUnitsField.append($('<div class="font-bold">').append(this));
                    } else {
                        $displayUnitsField.append($('<div>').append(this));
                    }

                    i++;
                });
            }

            $amountMeter.html(data['amount-meter']);
            $amountKilogram.html(data['amount-kilogram']);

            $materialPriceTotal.html(data['material-price-total']);
            $materialPriceVat.html(data['material-price-vat']);

            $divisionPriceTotal.html(data['division-price-total']);
            $divisionPriceVat.html(data['division-price-vat']);

            $priceTotal.html(data['price-total']);
            $priceVat.html(data['price-vat']);

            if (data['price-total'] !== undefined && !isInquiryArticle && parseFloat($(data['price-total']).html()) !== NaN && parseFloat($(data['price-total']).html()) !== 0) {
                $submitCartButton.prop("disabled", false);
                $addToShoppingListButton.prop("disabled", false);
            } else {
                $submitCartButton.prop("disabled", true);
                $addToShoppingListButton.prop("disabled", true);
            }
        },
        delay: function (callback, ms) {
            var timer = 0;

            return function () {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    callback.apply(context, args);
                }, ms || 0);
            };
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
