(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "login",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $('.login-form input.show-password').on('change', me.onLoginCheckboxChange);
            $('.navigation-entry.entry-account>.button').on('click', me.onAccountButtonClick);
            $('body').on('click', '*', me.onBodyClick);
        },
        onAccountButtonClick: function (event) {
            if (Foundation.MediaQuery.is('medium')) {
                var $accountButton = $(this),
                    $loginLayer = $accountButton.parent().find('.navigation-layer.login');

                if ($loginLayer.length > 0 && !$loginLayer.hasClass('is-open')) {
                    event.preventDefault();

                    $loginLayer.addClass('is-open');
                    $accountButton.addClass('layer-open');
                }
            }
        },
        onBodyClick: function (event) {
            var $loginLayer = $(this).parent().find('.navigation-layer.login'),
                $accountButton = $('.navigation-entry.entry-account>.button');

            if ($loginLayer.hasClass('is-open')) {
                var $target = $(event.target);

                if ($target !== $accountButton && $target.get(0) !== $accountButton.get(0) && !$target.parents('.button.login').length &&
                    $target !== $loginLayer && $target.get(0) !== $loginLayer.get(0) && !$target.parents('.navigation-layer.login').length
                ) {
                    $loginLayer.removeClass('is-open');
                    $accountButton.removeClass('layer-open');
                }
            }
        },
        onLoginCheckboxChange: function () {
            var $parent,
                $this = $(this);

            $parent = $this.parent();

            var $input = $('input.password', $parent);

            if ($input.attr('type') === 'password') {
                $input.attr('type', 'text');
            } else {
                $input.attr('type', 'password');
            }
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
