(function ($, window, undefined) {
    "use strict";

    var pluginName = "shoppingList",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $shareLink = me.$element.find('a.share-shopping-list'),
                $reveal = me.$element.find('.reveal');

            $reveal.on('closed.zf.reveal', me.onRevealClose);
            $shareLink.on('click', me.onShareClick);

            $('.navigation-entry.entry-shopping-list>.button').on('click', me.onShoppingListButtonClick);
            $('body').on('click', '*', me.onBodyClick);

            me.$element.on('click', '.button[type="submit"].add-to-shopping-list', me.onAddToListClick);
            me.$element.on('submit', 'form[data-change-quantity-in-shopping-list]', me.onChangeQuantityFormSubmit);
            me.$element.on('change', 'form[data-change-quantity-in-shopping-list] input', me.onQuantitySelectChange);
            me.$element.on('submit', 'form[data-remove-from-shopping-list]', me.onRemoveFormSubmit);
        },
        onShoppingListButtonClick: function (event) {
            if (Foundation.MediaQuery.is('medium')) {
                var $shoppingListButton = $(this),
                    $shoppingListLayer = $shoppingListButton.parent().find('.navigation-layer.shopping-list');

                if ($shoppingListLayer.length > 0 && !$shoppingListLayer.hasClass('is-open')) {
                    event.preventDefault();

                    $shoppingListLayer.addClass('is-open');
                    $shoppingListButton.addClass('layer-open');
                }
            }
        },
        onBodyClick: function (event) {
            var $shoppingListLayer = $(this).parent().find('.navigation-layer.shopping-list'),
                $shoppingListButton = $('.navigation-entry.entry-shopping-list>.button');

            if ($shoppingListLayer.hasClass('is-open')) {
                var $target = $(event.target);

                if ($target !== $shoppingListButton && $target.get(0) !== $shoppingListButton.get(0) && !$target.parents('.button.shopping-list').length &&
                    $target !== $shoppingListLayer && $target.get(0) !== $shoppingListLayer.get(0) && !$target.parents('.navigation-layer.shopping-list').length
                ) {
                    $shoppingListLayer.removeClass('is-open');
                    $shoppingListButton.removeClass('layer-open');
                }
            }
        },
        onRevealClose: function (event) {
            $(event.target).find('.ajax-container').html();
        },
        sendShareAjaxRequest: function (data, href, method = 'get') {
            var $reveal = me.$element.find('.reveal'),
                $ajaxContainer = $reveal.find('.ajax-container');

            $.ajax({
                type: method,
                data: data,
                url: href,
                success: function (response) {
                    if (data.type === 'share') {
                        $reveal.append(response);
                        var $shareLink = $ajaxContainer.find('a.share-link'),
                            $form = $ajaxContainer.find('form');

                        $shareLink.on('click', me.onShareLinkClick);
                        $form.on('submit', me.onShareFormSubmit);
                    } else if (data.type === 'share-link') {
                        var $shareLink = $ajaxContainer.find('a.share-link');
                        $shareLink.parent().html(response);
                    } else if (data.type === 'submit') {
                        $reveal.append(response);
                    }

                    var $copyButton = $reveal.find('button.copy-button'),
                        $input = me.$element.find('#link-input');

                    $copyButton.on('click', me.copyLink);
                    $input.on('focus', me.copyLink);

                    $reveal.foundation('open');
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }

            });
        },
        onChangeQuantityFormSubmit: function (event) {
            event.preventDefault();

            var data = $(this).serializeArray(),
                $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $amount = $('.header-navigation .navigation-entry.entry-shopping-list .entry-link .amount'),
                $overlay = $('body>.loader-overlay');

            $('body').append($loader);
            $overlay.addClass('is-visible');

            $.ajax({
                type: 'post',
                url: $(this).attr('action'),
                data: data,
                success: function (response) {
                    var $shoppingList = me.$element.find('.shopping-list-wrapper');

                    $amount.html(response['quantity']);
                    $shoppingList.replaceWith(response.html);

                    $loader.remove();
                    $overlay.removeClass('is-visible');

                    $shoppingList = me.$element.find('.shopping-list-wrapper');
                    Foundation.reflow($shoppingList.find('img'));
                },

                error: function () {
                    alert('error');

                    $loader.remove();
                    $overlay.removeClass('is-visible');
                },

                complete: function (e) {
                    window.location.reload()
                }
            });
        },

        onRemoveFormSubmit: function (event) {
            event.preventDefault();

            var $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $amount = $('.header-navigation .navigation-entry.entry-shopping-list .entry-link .amount'),
                $form = $(this),
                $overlay = $('body>.loader-overlay');

            $('body').append($loader);
            $overlay.addClass('is-visible');

            $.ajax({
                type: 'post',
                url: $form.attr('action'),
                data: $form.serializeArray(),
                success: function (response) {
                    var $shoppingList = me.$element.find('.shopping-list-wrapper');

                    $amount.html(response['quantity']);
                    $shoppingList.replaceWith(response.html);

                    $loader.remove();
                    $overlay.removeClass('is-visible');

                    $shoppingList = me.$element.find('.shopping-list-wrapper');
                    Foundation.reflow($shoppingList.find('img'));
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                    $loader.remove();
                    $overlay.removeClass('is-visible');
                }

            });
        },
        onShareFormSubmit: function (event) {
            event.preventDefault();

            var url = $(this).attr('action');

            me.sendShareAjaxRequest($(this).serializeArray(), url, 'post');
        },
        copyLink: function (event) {
            var $input = me.$element.find('#link-input');

            $input.select();
            document.execCommand("copy");
        },
        onShareLinkClick: function (event) {
            event.preventDefault();

            var url = $(this).attr('href');

            me.sendShareAjaxRequest({type: 'share-link'}, url);
        },
        onShareClick: function (event) {
            event.preventDefault();

            var url = $(this).attr('href');

            me.sendShareAjaxRequest({type: 'share'}, url);
        },
        onAddToListClick: function (event) {
            event.preventDefault();

            var $addToListButton = $(this),
                $form = $addToListButton.closest('form'),
                data = $form.serializeArray(),
                $reveal = me.$element.find('.reveal');


            if (!$addToListButton.hasClass('disabled')) {
                $.ajax({
                    type: 'post',
                    url: $form.attr('action'),
                    data: data,
                    success: function (response) {
                        $reveal.find('.content-stock').append(response);

                        var $addButton = $reveal.find('button[type="submit"]');

                        $addButton.on('click', me.onAddButtonClick);

                        $reveal.addClass('large');

                        $reveal.foundation('open');
                    },

                    beforeSend: function( xhr ) {
                        $reveal.addClass('stock-info');
                        $reveal.find('.content-stock').html("");
                    },

                    error: function () {
                        alert('error');
                    },

                    complete: function (e) {
                    }
                });
            }
        },
        onAddButtonClick: function (event) {
            event.preventDefault();

            var $addButton = $(this),
                $form = $addButton.closest('form'),
                data = $form.serializeArray(),
                $amount = $('.header-navigation .navigation-entry.entry-shopping-list .entry-link .amount'),
                $reveal = me.$element.find('.reveal');

            $.ajax({
                type: 'post',
                url: $form.attr('action'),
                data: data,
                success: function (response) {
                    if (response.success) {
                        $amount.html(response['quantity']);

                        var $notifications = me.$element.find('.notifications');

                        $notifications.append(response.html);
                        $reveal.foundation('close');
                    } else {
                        $reveal.prepend(response.html);
                    }
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onQuantitySelectChange: function (event) {
            var $form = $(this).parent();

            $form.submit();
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
