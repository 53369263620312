(function ($, window, undefined) {
    "use strict";

    var pluginName = "cart",
        defaults = {
            'openOverlayAfterAdd': true
        },
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            me.initCart();
        },
        initCart: function () {
            var $addMultipleButton = me.$element.find('.button[data-add-multiple-to-cart]'),
                $reveal = me.$element.find('.reveal.cart');

            $reveal.on('closed.zf.reveal', me.onRevealClose);

            me.$element.on('click', '.button[data-add-to-cart]', me.onAddButtonClick);
            $addMultipleButton.on('click', me.onAddMultipleButtonClick);

            me.$element.on('submit', 'form[data-remove-from-cart]', me.onRemoveFormSubmit);
            me.$element.on('submit', 'form[data-change-quantity-in-cart]', me.onChangeQuantityFormSubmit);
            me.$element.on('change', 'form[data-change-quantity-in-cart] input', me.onQuantitySelectChange);
            me.$element.on('click', 'a.share-cart', me.onShareCartClick);


            me.$element.on('click', '.reveal.cart a.share-link', me.onShareLinkClick);
            me.$element.on('submit', '.reveal.cart form', me.onShareFormSubmit);
            me.$element.on('click', '.reveal.cart button.copy-button', me.copyLink);
            me.$element.on('focus', '.reveal.cart #link-input', me.copyLink);
        },
        onRevealClose: function (event) {
            $(event.target).find('.ajax-container').html('');
        },
        sendShareAjaxRequest: function (data, href, method = 'get') {
            var $reveal = me.$element.find('.reveal.cart'),
                $ajaxContainer = $reveal.find('.content-stock');

            $.ajax({
                type: method,
                data: data,
                url: href,
                success: function (response) {
                    if (data.type === 'share') {
                        $ajaxContainer.append(response.html);
                    } else if (data.type === 'share-link') {
                        var $shareLink = $ajaxContainer.find('a.share-link');
                        $shareLink.parent().html(response.html);
                        alert('asdf')
                    } else {
                        $ajaxContainer.append(response.html);
                    }

                    $reveal.addClass('large');

                    if (response.success) {
                        $reveal.foundation('close');
                    } else {
                        $reveal.foundation('open');
                    }
                },

                beforeSend: function( xhr ) {
                    $reveal.addClass('stock-info');
                    $reveal.find('.content-stock').html("");
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }

            });
        },
        onShareFormSubmit: function (event) {
            event.preventDefault();

            var url = $(this).attr('action');

            me.sendShareAjaxRequest($(this).serializeArray(), url, 'post');
        },
        copyLink: function (event) {
            var $input = me.$element.find('#link-input');

            $input.select();
            document.execCommand("copy");
        },
        onShareLinkClick: function (event) {
            event.preventDefault();

            var url = $(this).attr('href');

            me.sendShareAjaxRequest({type: 'share-link'}, url);
        },
        onShareCartClick: function (event) {
            event.preventDefault();

            var url = $(this).attr('href');

            me.sendShareAjaxRequest({type: 'share'}, url);
        },
        onAddMultipleButtonClick: function (event) {
            event.preventDefault();

            var data = {'article-numbers': []},
                $addButton = $(this),
                $cartButton = $('.header-navigation .navigation-entry.entry-cart .entry-link'),
                $amount = $('.header-navigation .navigation-entry.entry-cart .entry-link .amount'),
                $total = $('.header-navigation .navigation-entry.entry-cart .entry-link .total'),
                $form = $(this).closest('form'),
                $inputs = $form.find('input.article-number:checked');

            $.each($inputs, function () {
                data['article-numbers'].push($(this).val());
            });

            $.ajax({
                type: 'post',
                url: $form.attr('action'),
                data: data,
                success: function (response) {
                    $amount.html(response['quantity']);
                    $total.html(response['total']);

                    if (response['cart-quantity'] > 0) {
                        $amount.removeClass('hide');
                    }

                    $addButton.addClass('success');

                    if (me.settings.openOverlayAfterAdd) {
                        $cartButton.trigger('click');
                    }

                    setTimeout(function () {
                        $addButton.removeClass('success');
                    }, 1000);
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onAddButtonClick: function (event) {
            event.preventDefault();

            var $addButton = $(this),
                $cartButton = $('.header-navigation .navigation-entry.entry-cart .entry-link'),
                $amount = $('.header-navigation .navigation-entry.entry-cart .entry-link .amounts'),
                $total = $('.header-navigation .navigation-entry.entry-cart .entry-link .total'),
                $form = $(this).closest('form'),
                data = $form.serializeArray();

            if ($addButton.val() && $addButton.attr('name')) {
                data.push({name: $addButton.attr('name'), value: $addButton.val()})
            }

            $.ajax({
                type: 'post',
                url: $form.attr('action'),
                data: data,
                success: function (response) {
                    $amount.html(response['quantityMessage']);
                    $total.html(response['total']);

                    console.log("2");
                    console.log($amount)
                    console.log($amount.text())
                    console.log($total)
                    console.log(response['quantityMessage'])
                    console.log(response['total'])

                    if (response['quantity'] > 0) {
                        $amount.removeClass('hide');
                    }

                    var $amountInput = $form.find('input.amount');
                    var $amountInputLength = $form.find('input.input-group-field');
                    var $parent = $addButton.closest('tr');
                    var $certificate = $('#certificate')

                    if ($parent.length > 1) {

                        $amountInput = $parent.find('input.amount');

                        $amountInput.val(0);
                        $amountInputLength.val(0);
                    } else {
                        $amountInput.val(0);
                        $amountInputLength.val(0);
                    }
                    
                    if($certificate.prop('checked')) $certificate.prop('checked', false);

                    $amountInput.trigger('change');
                    $addButton.addClass('success');

                    if (me.settings.openOverlayAfterAdd) {
                        $cartButton.trigger('click');
                    }

                    setTimeout(function () {
                        $addButton.removeClass('success');
                    }, 1000);
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        },
        onRemoveFormSubmit: function (event) {
            event.preventDefault();

            var $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $form = $(event.target),
                $formParent = $form.parent().parent().parent().parent(),
                $overlay,
                isCartOverlay = false;

            if ($formParent.hasClass('cart-overlay')) {
                $overlay = $formParent.find('.loader-overlay');

                $formParent.find('.cart-content').html($loader);
                $overlay.addClass('is-visible');

                isCartOverlay = true;
            } else {
                $overlay = $('body>.loader-overlay');

                $('body').append($loader);
                $overlay.addClass('is-visible');
            }

            $.ajax({
                type: 'post',
                url: $form.attr('action'),
                data: $form.serializeArray(),
                success: function (response) {
                    var $cartOverlay = $('.cart-overlay'),
                        $cartContent = $cartOverlay.find('.cart-content'),
                        $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                        $overlay = $cartOverlay.find('.loader-overlay'),
                        $amount = $('.header-navigation .navigation-entry.entry-cart .entry-link .amount'),
                        $total = $('.header-navigation .navigation-entry.entry-cart .entry-link .total'),
                        $cartWrapper = $('.cart-wrapper'),
                        $cartButton = $('.header-navigation .entry-cart .entry-link');

                    $amount.html(response['quantity']);
                    $total.html(response['total']);

                    $cartWrapper.html(response['html']);
                    Foundation.reflow($cartWrapper.find('img'));

                    if (isCartOverlay) {
                        $cartContent.html($loader);
                        $overlay.addClass('is-visible');

                        $.ajax({
                            type: 'get',
                            url: $cartButton.attr('href'),
                            dataType: 'html',
                            success: function (response) {
                                $cartContent.html(response);
                                Foundation.reflow($cartContent.find('img'));
                            },
                            complete: function () {
                                $loader.remove();
                                $overlay.removeClass('is-visible');
                            }
                        });
                    } else {
                        if (response['quantity'] <= 0) {
                            window.location.reload();
                        }
                    }
                },
                error: function () {
                    alert('error');
                },
                complete: function (e) {
                    $loader.remove();
                    $overlay.removeClass('is-visible');
                }
            });
        },
        onChangeQuantityFormSubmit: function (event) {
            event.preventDefault();

            var data = $(this).serializeArray(),
                $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $overlay = $('body>.loader-overlay'),
                targetCartItem = $(this).find('input[name="cart-item"]').val();

            $('body').append($loader);
            $overlay.addClass('is-visible');

            $.ajax({
                type: 'post',
                url: $(this).attr('action'),
                data: data,
                success: function (response) {
                    var $badge = $('.header-navigation .entry-cart .entry-link .badge'),
                        $cartWrapper = $('.cart-wrapper');

                    $badge.html(response['quantity']);
                    $cartWrapper.html(response['html']);

                    $loader.remove();
                    $overlay.removeClass('is-visible');

                    Foundation.reflow($cartWrapper.find('img'));
                },

                error: function () {
                    alert('error');

                    $loader.remove();
                    $overlay.removeClass('is-visible');
                },

                complete: function (e) {
                }
            });
        },
        onQuantitySelectChange: function (event) {
            var $form = $(this).parent();

            $form.submit();
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window);
