(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "extendNavigation",
        defaults = {
            advancerLeftClass: "advancer-left",
            advancerRightClass: "advancer-right",
            advancerLeft: "<button class='advancer-left'></button>",
            advancerRight: "<button class='advancer-right'></button>",
            iconLeft: "<span class='icon-angle-left'></span>",
            iconRight: "<span class='icon-angle-right'></span>",
            isActiveClass: "is-active",
            extendCart: true
        },
        positionLeft = 0,
        positionRight = 0,
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName,
            me.$headerNavigation = me.$element.find('.header-navigation'),
            me.$mainNavigation = me.$element.find('.main-navigation'),
            me.$navigationList = me.$mainNavigation.find('.navigation-list'),
            me.$navigationEntries = me.$navigationList.find('.navigation-entry'),
            me.$advancerLeft,
            me.$advancerRight;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            me.initNavigation();
            me.initScrollingNavigation();
        },
        initNavigation: function () {
            var $cartButton = me.$headerNavigation.find('.entry-cart .entry-link'),
                $mobileMenuButton = me.$headerNavigation.find('.entry-menu .entry-link');

            $cartButton.on('click', me.onCartButtonClick);
            $mobileMenuButton.on('click', me.onMobileMenuButtonClick);
            $('body').on('click', '[data-close-cart-overlay]', me.onCloseButtonClick);
        },
        initScrollingNavigation: function () {
            // var $advancerLeft = $(me.settings.advancerLeft),
            //     $advancerRight = $(me.settings.advancerRight);
            //
            // $advancerLeft.addClass(me.settings.advancerLeftClass);
            // $advancerRight.addClass(me.settings.advancerRightClass);
            // $advancerRight.addClass(me.settings.isActiveClass);
            // $advancerLeft.html(me.settings.iconLeft);
            // $advancerRight.html(me.settings.iconRight);
            //
            // me.$mainNavigation.prepend($advancerLeft);
            // me.$mainNavigation.append($advancerRight);
            //
            // me.$advancerLeft = $('.' + me.settings.advancerLeftClass);
            // me.$advancerRight = $('.' + me.settings.advancerRightClass);
            //
            // me.$advancerLeft.on('click', me.onAdvancerButtonClick);
            // me.$advancerRight.on('click', me.onAdvancerButtonClick);
        },
        // onAdvancerButtonClick: function (event) {
        //     var $this = $(event.currentTarget),
        //         width;
        //
        //     if ($this.hasClass(me.settings.advancerRightClass)) {
        //         width = $(me.$navigationEntries[positionLeft]).width();
        //         positionLeft++;
        //         me.$navigationList.animate({scrollLeft: '+=' + width}, 'slow', me.animateCompleteCallback);
        //     } else {
        //         width = $(me.$navigationEntries[me.$navigationEntries.length - positionRight - 1]).width();
        //         positionRight++;
        //         me.$navigationList.animate({scrollLeft: '-=' + width}, 'slow', me.animateCompleteCallback);
        //     }
        // },
        // animateCompleteCallback: function () {
        //     if (me.$navigationList.scrollLeft() > 0) {
        //         me.$advancerLeft.addClass(me.settings.isActiveClass);
        //     } else {
        //         me.$advancerLeft.removeClass(me.settings.isActiveClass);
        //     }
        //
        //     var max = me.$navigationList.get(0).scrollWidth - me.$navigationList.get(0).clientWidth;
        //
        //     if (me.$navigationList.scrollLeft() >= max) {
        //         me.$advancerRight.removeClass(me.settings.isActiveClass);
        //     } else {
        //         me.$advancerRight.addClass(me.settings.isActiveClass);
        //     }
        // },
        // @todo: Strip parameters from url before the compare statement
        onMobileMenuButtonClick: function (event) {
            event.preventDefault();

            var $offCanvas = $('.off-canvas.navigation'),
                $navigationContent = $offCanvas.find('.navigation-content'),
                $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $overlay = $offCanvas.find('.loader-overlay');

            if (!$navigationContent.html().length) {
                $navigationContent.html($loader);
                $overlay.addClass('is-visible');

                $.ajax({
                    type: 'get',
                    url: $(this).data('ajax-url'),
                    dataType: 'html',
                    success: function (response) {
                        $navigationContent.html(response);

                        var $drilldownMenu = $navigationContent.find('.drilldown.menu');

                        new Foundation.Drilldown($drilldownMenu, {});

                        $drilldownMenu.languageSwitcher();

                        me.activateCurrentMobileMenu($drilldownMenu);
                    },
                    complete: function () {
                        $loader.remove();
                        $overlay.removeClass('is-visible');
                    }
                });
            }
        },
        activateCurrentMobileMenu: function ($drilldownMenu) {
            var $drilldownSubMenus = $drilldownMenu.find('.is-drilldown-submenu');

            $.each($drilldownSubMenus, function () {
                var $drilldownSubMenu = $(this),
                    $drilldownLinks = $drilldownSubMenu.find('a');

                $.each($drilldownLinks, function () {
                    var $drilldownLink = $(this),
                        href = $drilldownLink.attr('href');

                    if (window.location.href.replace(window.location.protocol + "//" + window.location.host, '') === href) {
                        var $subMenu = $drilldownLink.closest('.is-drilldown-submenu');

                        $subMenu.addClass('is-active');
                        $subMenu.removeClass('invisible');
                    }
                });
            });

        },
        onCloseButtonClick: function (event) {
            event.preventDefault();
            var $cartOverlay = $('.cart-overlay'),
                $cartButton = me.$headerNavigation.find('.entry-cart .entry-link');

            $cartOverlay.removeClass('is-opened');
            $cartButton.removeClass('is-active');
            $('body').removeClass('cart-container-opened');
        },
        onCartButtonClick: function (event) {
            if (me.settings.extendCart) {
                event.preventDefault();

                var $cartButton = $(this),
                    $cartOverlay = $('.cart-overlay'),
                    $cartContent = $cartOverlay.find('.cart-content'),
                    $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                    $overlay = $cartOverlay.find('.loader-overlay');

                if (!$cartOverlay.hasClass('is-opened')) {
                    $cartContent.html($loader);
                    $overlay.addClass('is-visible');
                    $cartOverlay.addClass('is-opened');
                    $cartButton.addClass('is-active');
                    $('body').addClass('cart-container-opened');

                    $.ajax({
                        type: 'get',
                        url: $(this).attr('href'),
                        dataType: 'html',
                        success: function (response) {
                            $cartContent.html(response);

                            Foundation.reflow($cartContent.find('img'));
                        },
                        complete: function () {
                            $loader.remove();
                            $overlay.removeClass('is-visible');
                        }
                    });
                } else {
                    $cartOverlay.removeClass('is-opened');
                    $cartButton.removeClass('is-active');
                    $('body').removeClass('cart-container-opened');
                }
            }
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
