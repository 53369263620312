(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "ares",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $(document).on('click', 'button[data-ares-button]', me.onAresButtonClick);
        },

        onAresButtonClick: function (event) {
            event.preventDefault();

            var $button = $(this),
                $form = $button.closest('form'),
                $companyIdent = $form.find('.company-ident'),
                $input = $companyIdent.find('input'),
                ico = $input.val(),
                $loader = $('<div class="ring-loader"><div></div><div></div><div></div><div></div></div>'),
                $errorMessage = $button.parent().find('.error-message');

            if (ico.length === 8 && Number.isInteger(parseInt($input.val()))) {
                $button.addClass('loader-active');
                $button.append($loader);
                $button.prop("disabled", true);

                $.ajax({
                    method: 'POST',
                    url: $button.data('href'),
                    data: {ico: ico},
                    success: function (response) {
                        if (response.success) {
                            var $companyName = $form.find('.company-name'),
                                $companyVAT = $form.find('.company-vat'),
                                $invoiceStreet = $(document).find('.street'),
                                $invoiceCity = $(document).find('.city'),
                                $invoiceZIP = $(document).find('.zip');

                            $companyName.find('input').val(response.address.name);
                            $companyVAT.find('input').val(response.address.vat);
                            $invoiceStreet.find('input').val(response.address.street);
                            $invoiceCity.find('input').val(response.address.city);
                            $invoiceZIP.find('input').val(response.address.zip);

                            $errorMessage.html('&nbsp;');
                            me.removeError($companyIdent);
                        } else {
                            var $message = $('<div class="text-color-alert"></div>').html(response.error);

                            $errorMessage.html($message);
                        }
                    },
                    complete: function () {
                        $button.removeClass('loader-active');
                        $button.find('.ring-loader').remove();
                        $button.prop("disabled", false);
                    }
                });
            } else {
                me.addError($companyIdent);
            }
        },
        addError: function ($element) {
            var $label = $element.find('label'),
                $input = $element.find('input');

            $label.addClass('is-invalid-label');
            $input.addClass('is-invalid-input');
        },
        removeError: function ($element) {
            var $label = $element.find('label'),
                $input = $element.find('input');

            $label.removeClass('is-invalid-label');
            $input.removeClass('is-invalid-input');
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
