(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "initSearch",
        defaults = {},
        timeoutHandler,
        $body = $('body'),
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName,
            me.$searchEntry = me.$element.find('.entry-search'),
            me.$searchButton = me.$searchEntry.find('.entry-link'),
            me.$mobileSearchButton = me.$element.find('.entry-mobile-search .entry-link'),
            me.$searchForm = me.$searchEntry.find('.search-form'),
            me.$searchInput = me.$searchForm.find('.search-input'),
            me.$searchResultsContainer = me.$searchEntry.find('.search-results');

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            me.initSearch();
        },
        initSearch: function () {
            me.settings.url = me.$searchForm.attr('action');

            me.$searchButton.on('click', me.onButtonClick);
            me.$mobileSearchButton.on('click', me.onMobileSearchButtonClick);
            $body.on('keydown paste input', '.search-form .search-input', me.onInputChange);
            $body.on('focus blur', '.search-form .search-input', me.onInputFocusBlur);
            $body.on('click', '.search-results button[data-close-search-overlay]', me.onCloseButtonClick);
            $body.on('click', '*', me.onBodyClick);
            $body.on('change', '.search-results input.amount', me.onAmountFieldChange)
        },
        onAmountFieldChange: function (event) {
            var $amountField = $(this),
                value = $amountField.val(),
                $form = $amountField.closest('.add-to-cart-form '),
                $button = $form.find('button[type="submit"].cart-button');

            if (value > 0) {
                $button.prop('disabled', false)
            } else {
                $button.prop('disabled', true)
            }
        },
        onInputFocusBlur: function (event) {
            if (event.type === 'focusin') {
                $('.entry-search').addClass('focus');
                $('body').addClass('search-container-focus');

                if (me.$searchResultsContainer.html().length) {
                    me.$searchResultsContainer.addClass('is-active');
                    $('body').addClass('search-container-opened');
                }
            }
        },
        onMobileSearchButtonClick: function (event) {
            event.preventDefault();

            me.search('');
        },
        onButtonClick: function (event) {
            event.preventDefault();

            me.$element.toggleClass('search-form-is-active');
        },
        onCloseButtonClick: function (event) {
            me.$searchResultsContainer.removeClass('is-active');
            $('body').removeClass('search-container-opened');
            $('body').removeClass('search-container-focus');
            $('.entry-search').removeClass('focus');
        },
        onBodyClick: function (event) {
            var target = event.target;

            if ((me.$searchResultsContainer.hasClass('is-active') || me.$searchInput.closest('.entry-search').hasClass('focus')) &&
                target !== me.$searchResultsContainer.get(0) &&
                target !== me.$searchInput.get(0) &&
                $(target).parents('.search-results').length === 0
            ) {
                me.$searchResultsContainer.removeClass('is-active');
                $('body').removeClass('search-container-opened');
                $('body').removeClass('search-container-focus');
                $('.entry-search').removeClass('focus');
            }
        },
        onInputChange: function (event) {
            clearTimeout(timeoutHandler);

            var $input = $(this);

            if ($input.closest('.entry-search').hasClass('focus')) {
                timeoutHandler = setTimeout(function () {
                    if ($input.closest('.entry-search').hasClass('focus')) {
                        var query = $(event.currentTarget).val();

                        if (query.length > 2) {
                            me.search(query);
                        }
                    }
                    clearTimeout(timeoutHandler);
                }, 1000);
            }
        },
        search: function (query) {
            $.ajax({
                type: 'get',
                url: me.settings.url,
                data: {search: query},
                success: function (response) {
                    me.$searchResultsContainer.html(response);
                    me.$searchResultsContainer.addClass('is-active');
                    $('body').addClass('search-container-opened');

                    Foundation.reflow(me.$searchResultsContainer.find('img'));

                    $('.search-form .search-input').focus().val('').val(query);
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
