import '../scss/app.scss';

var $ = require('jquery');
var jQuery = global.$ = global.jQuery = $;

import 'jquery-zoom';
import 'what-input';
import 'foundation-sites';
import 'owl.carousel';
import 'jquery.cookie';
import '@fancyapps/fancybox';

import '../../ottcomputer/js/app/jquery.smooth-scroll';
import '../../ottcomputer/js/app/jquery.amount-field';
import '../../ottcomputer/js/app/jquery.cart';
import '../../ottcomputer/js/app/jquery.shopping-list';
import '../../ottcomputer/js/app/jquery.extend-navigation';
import '../../ottcomputer/js/app/jquery.init-search';
import '../../ottcomputer/js/app/jquery.product-listing';
import '../../ottcomputer/js/app/jquery.product-detail';
import '../../ottcomputer/js/app/jquery.sidebar-filter';
import '../../ottcomputer/js/app/jquery.buybox-mobile';
import '../../ottcomputer/js/app/jquery.language-switcher';
import '../../ottcomputer/js/app/jquery.init-sidebar';
import '../../ottcomputer/js/app/jquery.account';
import '../../ottcomputer/js/app/jquery.checkout';
import '../../ottcomputer/js/app/jquery.login';
import '../../ottcomputer/js/app/jquery.misc';
import '../../ottcomputer/js/app/jquery.mega-menu';
import '../../ottcomputer/js/app/jquery.cookie-notice';
import '../../ottcomputer/js/app/jquery.slider';
import '../../ottcomputer/js/app/jquery.stock-info';
import './app/jquery.registration';
import './app/jquery.ares';

var $html = $('html');

$html.removeClass('no-js');
$html.addClass('js');

$(document).ready(function () {
    var $mainHeader = $('.main-header');

    $(document).languageSwitcher();
    $(document).slider();
    $(document).smoothScroll();
    $(document).amountField();
    $mainHeader.initSearch();
    $mainHeader.extendNavigation();
    $mainHeader.megaMenu();
    $(document).cart({'openOverlayAfterAdd': false});
    $(document).shoppingList();
    $(document).login();
    $(document).registration();
    $(document).ares();
    $(document).cookieNotice();
    $(document).misc();
    $(document).sidebarFilter();
    $(document).stockInfo();
    $('.sidebar').initSidebar();
    $('.is-ctl-product.is-act-index').productListing();
    $('.is-ctl-product.is-act-detail').productDetail();
    $('.is-ctl-product.is-act-detail').buyboxMobile();
    $('.is-ctl-account').account();
    $('.is-ctl-checkout').checkout();
});

$(document).foundation();
