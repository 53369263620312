(function ($, window, undefined) {
    "use strict";

    var pluginName = "productListing",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName,
            me.$listingHeader = me.$element.find('.listing-header'),
            me.isActiveClass = 'is-expanded',
            me.$articleFilters = me.$element.find('.article-filters');

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var $button = me.$listingHeader.find('button.expand-filters'),
                $sortingSelect = me.$listingHeader.find('select.sorting'),
                $buttonToggle = me.$element.find('button.toggle-mobile'),
                $amountFields = me.$element.find('input.amount');
                //$stockButton = me.$element.find('.in-stock');

            $sortingSelect.on('change', me.onSortingSelectChange);
            $button.on('click', me.onButtonClick);
            $buttonToggle.on('click', me.onButtonToggleClick);
            $amountFields.on('change', me.onAmountFieldChange);
            $( document ).ajaxComplete(function() {
                $amountFields = me.$element.find('input.amount');
                $amountFields.on('change', me.onAmountFieldChange);
            });
            //$stockButton.on('click', me.onShowStock);
        },
        onSortingSelectChange: function (event) {
            if ($(this).val().length) {
                window.location.href = $(this).val();
            }
        },
        onAmountFieldChange: function (event) {
            var $amountField = $(this),
                value = $amountField.val(),
                $amountFieldRow = $amountField.closest('.article, tr'),
                $button = $amountFieldRow.find('button[type="submit"]');

            if (value > 0) {
                $button.prop('disabled', false)
            } else {
                $button.prop('disabled', true)
            }
        },
        onButtonClick: function (event) {
            me.$articleFilters.toggleClass(me.isActiveClass);
        },
        onButtonToggleClick: function (event) {
            var $button = $(this),
                $buttonTr = $button.closest('tr'),
                $targetTr = $buttonTr.next();

            $targetTr.toggleClass('active');

            if ($button.hasClass('open')) {

            }

            $button.toggleClass('open');
        },
        /*onShowStock: function (event) {
            event.preventDefault();
            var $link = $(this),
                $reveal = me.$element.find('.reveal');

            $.ajax({
                type: 'post',
                url: $link.attr('href'),
                success: function (response) {
                    $reveal.find('.content-stock').append(response);

                    $reveal.addClass('large');

                    $reveal.foundation('open');
                },

                beforeSend: function( xhr ) {
                    $reveal.find('.content-stock').html("");
                },

                error: function () {
                    alert('error');
                },

                complete: function (e) {
                }
            });

        }*/
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

    $(".article-header").height(50)

})(jQuery, window);
