(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "registration",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $(document).on('change', '.registration .collapse-able.container-heading input[type="checkbox"]', me.onHeadingCheckboxChange);
            $(document).on('click', '.registration .collapse-able.container-heading', me.onHeadingClick);
        },
        onHeadingClick: function (event) {
            var $heading = $(this),
                $checkbox = $heading.find('input[type="checkbox"]'),
                checked = $heading.hasClass('is-collapsed');
            console.log(checked,$checkbox,$heading);

            $checkbox.prop('checked', checked).trigger('change');
        },
        onHeadingCheckboxChange: function (event) {
            var $checkbox = $(this),
                $inputs = $('.registration-form input[data-required-condition="#' + $checkbox.attr('id') + '"]'),
                checked = $checkbox.prop('checked');
            console.log(checked,$checkbox);

            $.each($inputs, function () {
                var $input = $(this),
                    $label = $input.parent().find('label');

                $input.prop('required', checked);

                if (checked) {
                    $label.addClass('required');
                } else {
                    $label.removeClass('required');
                }
            })
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
